<template>
	<b-container fluid>
		<bo-page-title />
		<section id="contact">
			<b-card v-if="isList" no-body>
				<template #header>
					<div class="d-flex align-items-center">
						<h5 class="card-title">Inbox</h5>

						<div class="ml-3">
							<b-button class="btn-rounded" size="sm" :variant="filterByStatus=='all'?'primary':'outline-primary'"
								:to="{name:$route.name, query:Object.assign({},$route.query||{}, {page:1, filterByStatus:'all'})}">
								All <span>({{ countData.all || 0 }})</span>
							</b-button>
							<b-button class="btn-rounded ml-1" size="sm" :variant="filterByStatus=='unread'?'primary':'outline-primary'"
								:to="{name:$route.name, query:Object.assign({}, $route.query||{}, {page:1, filterByStatus:'unread'})}">
								Unread <span>({{ countData.unread || 0 }})</span>
							</b-button>
							<b-button class="btn-rounded ml-1" size="sm" :variant="filterByStatus=='read'?'primary':'outline-primary'"
								:to="{name:$route.name, query:Object.assign({}, $route.query||{}, {page:1, filterByStatus:'read'})}">
								Read <span>({{ countData.read || 0 }})</span></b-button>
						</div>
					</div>
				</template>
				<b-card-body>
					<b-row class="align-items-center">
						<b-col md>
							<b-button variant="danger" v-b-tooltip.hover title="Delete Selected Data" :disabled="!idSelected.length" @click="deleteBulkData()">
								<i class="icon-trash"></i>
							</b-button>
						</b-col>
						<b-col md="auto">
							<div class="d-flex">
									<b-form @submit.prevent="doFilter()" class="d-flex">
									<b-input-group>
									<b-form-input v-model="filter.search" v-on:keyup.13="$emit('search')" placeholder="Type keyword then enter..."></b-form-input>
									<b-input-group-append>
										<b-button @click="doFilter()" variant="success"><i class="fas fa-search"></i></b-button>
									</b-input-group-append>
									</b-input-group>
									<b-button
										class="ml-1 d-inline-flex align-items-center"
										variant="outline-success"
										@click="doReset()"
									>Reset</b-button>
								</b-form>
							</div>
						</b-col>
					</b-row>
				</b-card-body>
				<table class="table table-striped">
					<thead>
						<tr>
							<th>
								<b-form-checkbox v-model="checkAll" value="Y" unchecked-value="N"></b-form-checkbox>
							</th>
							<th>#</th>
							<th>Sender</th>
							<th>Contact</th>
							<th>Job Name</th>
							<th>Date</th>
							<th>Status</th>
							<th>Action</th>
						</tr>
					</thead>

					<tbody>
						<template v-if="dataList">
							<tr v-for="(v, k) in dataList" :key="`dl-${k}`">
								<td>
									<b-form-checkbox v-model="idSelected" :value="v.afca_id">
									</b-form-checkbox>
								</td>
								<td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
								<td>{{ v.afca_name }}</td>
								<td>
									<span class="d-inline-block">
										<i class="ti-email mr-1"></i>
										<span>{{ v.afca_email }}</span>
									</span><br/>
									<span class="d-inline-block">
										<i class="icon-phone mr-1"></i>
										<span>{{ v.afca_phone_number }}</span>
									</span>
								</td>
								<td>
									{{ v.afca_job_name }}
								</td>
								<td>
									{{ v.afca_created_date | moment('LLL') }}
								</td>

								<td>
									<b-badge variant="success" v-if="v.afca_status == 'R'">Read</b-badge>
									<b-badge variant="warning" v-else>Unread</b-badge>
								</td>

								<td>
									<b-button v-if="moduleRole('detail')" variant="outline-info" class="btn-icon" v-b-tooltip.hover="'Detail'" @click="detailCareer(v)"><i class="icon-eye"></i></b-button>
								</td>
							</tr>

							<tr v-if="!(dataList||[]).length">
								<td colspan="10">
									<div class="text-center">
										<b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
										<h4 align="center"><span v-if="Object.keys(filter).length">No data shown</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
									</div>
								</td>
							</tr>
						</template>

						<tr v-else>
							<td colspan="10">
								<div class="text-center text-danger my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Loading...</strong>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<template #footer>
					<b-pagination v-if="data.total > data.per_page" class="mb-0 justify-content-end" v-model="pageNo"
						:per-page="data.per_page" :total-rows="data.total" />
				</template>
			</b-card>
			<b-modal
				id="careerDetail"
				title="Career Detail"
				centered
				no-close-on-backdrop
			>
				<b-row class="gx-0 gy-2">
					<b-col md="6">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Name</strong>
								<p>{{row.afca_name}}</p>
							</div>
						</div>
					</b-col>
					<b-col md="6">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Job Name</strong>
								<p>{{row.afca_job_name || '-'}}</p>
							</div>
						</div>
					</b-col>
					<b-col md="6">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Phone Number</strong>
								<p>{{row.afca_phone_number}}</p>
							</div>
						</div>
					</b-col>
					<b-col md="6">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Email</strong>
								<p>{{row.afca_email || '-'}}</p>
							</div>
						</div>
					</b-col>
					<b-col cols="12">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Interest Reason</strong>
								<p style="white-space: pre-line;">{{row.afca_interest_reason}}</p>
							</div>
						</div>
					</b-col>
					<b-col cols="12">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>CV</strong>
								
								<a :href="uploader(row.afca_cv)" target="_blank" class="d-block mt-2 btn btn-icon btn-outline-success" v-if="row.afca_cv">
									<i class="ti-download"></i>
								</a>

								<div class="mt-2" v-else></div>
							</div>
						</div>
					</b-col>
					<b-col md="12">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Status</strong>
								<div class="mt-1">
									<b-badge v-if="row.afca_status == 'D'" variant="warning">Unread</b-badge>
									<b-badge v-else-if="row.afca_status == 'R'" variant="success">Read</b-badge>
								</div>
							</div>
						</div>  
					</b-col>
					<b-col md=12 v-if="row.afca_created_date">
						<div class="ip-details px-0">
							<div class="ipd-item">
								<strong>Date</strong>
								<p v-if="row.afca_created_date">Created at: {{ row.afca_created_date | moment('LLLL') }}</p>
								<p v-if="row.afca_read_date">Read at: {{ row.afca_read_date | moment('LLLL') }}</p>
							</div>
						</div>
					</b-col>
				</b-row>
				<template #modal-footer>
					<b-button variant="primary" @click.prevent="closeModalDetail">Close</b-button>
				</template>
			</b-modal>
		</section>
	</b-container>
</template>

<script>
	import GlobalVue from '@/libs/Global.vue'
	import Gen from '@/libs/Gen'
	const _ = global._


	export default {
		name: 'BoCareer',
		extends: GlobalVue,
		computed: {
			filterByStatus() {
				return this.$route.query.filterByStatus || "all"
			}
		},
		mounted() {
			this.$root.$on('bv::modal::hidden', () => {
				this.apiGet()
			})
			this.apiGet()
		},
		data() {
			return {
				countData: {},
				seoRow: {},
				heroEditorOpen: false,
				inboxFields: [{
					key: 'num',
					label: '#'
				}, 
				'sender', 
				'contact', 
				'job_name', 
				'date',
				'status',
				'actions', ],
				filter: {},

				checkAll: "N",
				idSelected: [],
			}
		},
		methods: {
			detailCareer(item){
				this.row = _.clone(item)
				if(this.row.afca_status == 'D'){
					this.loadingOverlay = true
					Gen.apiRest("/do/"+ this.modulePage,
						{ data:{ type:'read', id: item.afca_id}}, 
						"POST"
					).then(() => {
						this.loadingOverlay = false
						this.$bvModal.show('careerDetail')
					}).catch(() => {
						this.loadingOverlay = false
						this.$bvModal.show('careerDetail')
					})
				}else{
					this.$bvModal.show('careerDetail')
				}
			},
			closeModalDetail(){
				this.row = {}
				this.$bvModal.hide('careerDetail')
			},
			deleteBulkData(){
				this.$swal.fire({
					title: "Are You Sure Want to Delete These Datas?",
					text: "The datas were deleted can't restore",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#d33",
					cancelButtonColor: "#3085d6",
					confirmButtonText: "Yes, delete!",
					customClass: {
						confirmButton: "btn btn-primary",
						cancelButton: "btn btn-outline-danger ml-1",
					},
					buttonsStyling: false,
				}).then(button => {
					if(button.isConfirmed){
						this.swalLoading()

						Gen.apiRest("/do/"+ this.modulePage,
							{ data:{ type:'delete-bulk', checkAll: this.checkAll, idSelected: this.idSelected}}, 
							"POST"
						).then(res => {
							this.$swal({
								icon: 'success',
								title: res.data.message
							}).then(() => {
								this.apiGet()
								this.$set(this, 'idSelected', [])
								this.$set(this, 'checkAll', 'N')
							})

						}).catch(err => {
							this.$swal({
								icon: 'error',
								text: err.response.data.message || 'Something went wrong!'
							})
						})
					}
				})
			},
		},
		watch: {
			$route() {
				this.apiGet()
			},
			'$route.params.pageSlug': function () {
				this.$set(this, 'filter', {})
			},
			'dataList'(value){
				if((value||[]).length){
					if(this.checkAll == 'Y') this.$set(this, 'idSelected', value.map(dl => dl.afca_id))
				}
			},
			'checkAll'(value){
				if(value == 'Y') { 
					this.$set(this, 'idSelected', this.dataList.map(dl => dl.afca_id))
				} else {
					this.$set(this, 'idSelected', [])
				}
			}
		}
	}
</script>